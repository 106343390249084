
.slide-notes-tab {
    width: 100%;
    min-height: 600px;
    padding: 14px;
}

.slide-notes-tab .editor-container {
    margin: 20px 0;
}
