body {
    margin: 0;
    padding: 0;
    font-family: sans-serif;
}

body .MuiAppBar-root {
    border: none;
}

.actions {
    display:flex;
}

.actions > div {
    display:flex;
    width: 50%;
}

.actions > div.cancel {
    justify-content: flex-end;
}
