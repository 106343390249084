
.slide-choices-tab {
    width: 100%;
    min-height: 600px;
    display: grid;
    grid: "edit details" / 75% 1fr;
}

.slide-choices-edit {
    grid-area: edit;
    padding: 14px 0;
}

.slide-choices-edit > h4 {
    margin: 10px 0;
}

.slide-choices-details {
    grid-area: details;
    padding: 10px;
}

.slide-choices-details > .MuiPaper-root > .MuiTypography-h6 {
    padding: 6px 10px;
}

.slide-image-placeholder {
    width: 100%;
    padding-top: 56.25%;
    pointer-events: none;
    background: #000;
}

.slide-image-placeholder h3 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0;
    color: #fff;
}

.slide-choices-wrapper {
    position: relative;
}

.slide-image-container {
    display: flex;
    pointer-events: none;
}

.slide-image-container img {
    width: 100%;
    height: auto;
}

.slide-choices-workspace {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.slide-choices-workspace > div {
    width: 100%;
    height: 100%;
}

.slide-choices-workspace > div .choice-rect {
    position: absolute;
    background: rgba(255, 0, 0, 0.6);
    border: none;
}

.slide-choices-workspace > div .choice-rect.valid-destination {
    background: rgba(0, 255, 0, 0.6);
}

.slide-choices-workspace > div .choice-rect .MuiTypography-root {
    color: black;
    pointer-events: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.slide-choices-workspace > div .dragging-rect {
    background: none;
    border: 3px solid red;
}
