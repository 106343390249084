
.choice-destination-modal {
    position: absolute;
    width: 45%;
    min-width: 600px;
    background-color: white;
    padding: 10px;

    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.choice-destination-modal .MuiPaper-root {
    padding: 10px;
}

.choice-destination-modal:focus {
    outline: none;
}

.choice-destination-modal fieldset.MuiFormControl-root {
    width: calc(100% - 20px);
    padding: 10px;
}

.choice-destination-modal .MuiFormControl-root {
    margin: 8px 0;
}

.choice-destination-group-header {
    display: flex;
}

.choice-destination-group-header .MuiButtonBase-root {
    margin-left: auto;
}

.choice-destination-group-header .MuiButtonBase-root .choice-destination-link {
    color: black;
}

.choice-destination-group-header .MuiButtonBase-root.Mui-disabled .choice-destination-link {
    color: rgba(0, 0, 0, 0.26);
}
