.bulk-upload-modal {
    position: absolute;
    width: 45%;
    min-width: 600px;
    background-color: white;
    padding: 10px;

    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.bulk-upload-modal .MuiPaper-root {
    padding: 10px;
}

.bulk-upload-modal:focus {
    outline: none;
}

.bulk-upload-modal fieldset.MuiFormControl-root {
    width: calc(100% - 20px);
    padding: 10px;
}

.bulk-upload-modal .MuiFormControl-root {
    margin: 8px 0;
}

.bulk-upload-modal .dropzone-container {
    margin: 20px 0;
}
