.strand-head,
.set-head {
    white-space: nowrap;
}

.strand-head.empty,
.set-head.empty,
.block-head.empty {
    opacity: .4;
}

.MuiTableBody-root:hover > tr > td[rowspan]:first-child,
.MuiTableBody-root > tr > td.hovered,
.MuiTableHead-root > tr > th.hovered {
    background-color: rgba(0, 0, 0, 0.04);
}

.MuiTableBody-root:hover > tr:first-child:hover > td:first-child {
    background-color: transparent;
}

.MuiTableBody-root > tr > td.set-content:hover {
    background-color: #fff !important;
}
