
.categories-list-container {
  padding: 10px;
}

.categories-list-container .uncategorized-tile {
  background-color: #476a8b;
}

.categories-list-container .uncategorized-tile .MuiGridListTileBar-root {
  background: linear-gradient(to top, rgba(0,0,0,0.8) 0%,rgba(0,0,0,0.4) 70%,rgba(0,0,0,0) 100%);
}