
.choice-list-item-form {
    padding: 12px;
}

.choice-list-item-form .MuiTextField-root {
    margin: 10px 6px;
}

.choice-list-item-form .choice-list-item-delete {
    color: red;
    text-align: center;
    margin: 0 10px;
}